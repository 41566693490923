<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Evaluaciones</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Administrativo</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Evaluaciones</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-12">
                    <h5 class="card-title">
                      Formulario {{ accion }} Evaluaciones
                    </h5>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <!-- Campo Nombre -->
                  <div class="col-md-3 col-sm-12">
                    <div class="form-group">
                      <label for="nombre">Nombre</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="razon_social"
                        v-model="form.nombre"
                        :class="
                          $v.form.nombre.$invalid ? 'is-invalid' : 'is-valid'
                        "
                      />
                    </div>
                  </div>
                  <!-- Campo linea de negocio-->
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="linea_negocio">Línea De Negocio</label>
                      <v-select
                        :class="[
                          $v.form.linea_negocio_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                        ]"
                        v-model="lineas_negocios"
                        placeholder="Lineas Negocio"
                        label="nombre"
                        :options="listasForms.lineas_negocios"
                        class="form-control form-control-sm p-0"
                        @input="getSelectLineas()"
                      ></v-select>
                    </div>
                  </div>
                  <!-- Campo Estado -->
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="estado">Estado</label>
                      <select
                        class="form-control form-control-sm"
                        v-model="form.estado"
                        :class="
                          $v.form.estado.$invalid ? 'is-invalid' : 'is-valid'
                        "
                      >
                        <option value="">Seleccione...</option>
                        <option
                          v-for="estado in listasForms.estados"
                          :key="estado.id"
                          :value="estado.id"
                        >
                          {{ estado.descripcion }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group col-md-12">
                    <label>Observación</label>
                    <textarea
                      class="form-control form-control-sm"
                      v-model="form.observacion"
                    ></textarea>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-6" v-if="form.id">
                    <h5>Secciones</h5>
                  </div>
                  <!-- Boton de agregar Seccion -->
                  <div
                    class="col-lg-6 col-md-6 col-sm-6 text-right"
                    v-if="form.id"
                  >
                    <button
                      data-target="#Modal_lineas"
                      data-toggle="modal"
                      class="btn btn-info"
                      v-on:click="cleanerModal('POST', 'Crear')"
                    >
                      Agregar Sección
                    </button>
                  </div>
                  <div class="col-lg-12 col-md-6 col-sm-12"></div>
                  <div
                    class="col-lg-12 col-md-12 col-sm-12"
                    style="padding-top: 15px"
                  >
                    <div class="panel">
                      <div class="panel-heading">
                        <div class="accordion" id="accordionExample">
                          <div
                            class="card"
                            v-for="item in secciones"
                            :key="item.id"
                          >
                            <div
                              class="card-header"
                              v-bind:id="'heading' + item.id"
                              style="background-color: #e6e6e6"
                            >
                              <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                  <h5 class="mb-0">
                                    <button
                                      class="btn btn-link collapsed"
                                      type="button"
                                      data-toggle="collapse"
                                      v-bind:data-target="'#collapse' + item.id"
                                      aria-expanded="false"
                                      v-on:click="loadItems(item.id)"
                                      v-bind:aria-controls="
                                        'collapse' + item.id
                                      "
                                    >
                                      <u
                                        ><strong>{{ item.nombre }}</strong></u
                                      >
                                    </button>
                                  </h5>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                  <div class="btn-group float-right">
                                    <button
                                      class="btn btn-sm btn-danger"
                                      v-on:click="destroySeccion(item.id)"
                                    >
                                      <i class="fas fa-trash"></i>
                                    </button>
                                    <button
                                      data-target="#Modal_lineas"
                                      data-toggle="modal"
                                      class="btn btn-sm bg-navy"
                                      v-on:click="
                                        cleanerModal('PUT', 'Editar', item.id)
                                      "
                                    >
                                      <i class="fas fa-edit"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <!-- Btn de ediccion de la seccion -->
                            </div>
                            <div
                              v-bind:id="'collapse' + item.id"
                              class="collapse"
                              v-bind:aria-labelledby="'heading' + item.id"
                              data-parent="#accordionExample"
                            >
                              <div class="card-body">
                                <div class="row">
                                  <!-- Formualario ingreso de items -->
                                  <div class="col-lg-12 col-md-12 col-sm-12">
                                    <button
                                      type="button"
                                      class="btn btn-primary"
                                      style="float: right"
                                      data-toggle="modal"
                                      data-target="#Modal_Items"
                                      v-on:click="limpiarModalItems()"
                                    >
                                      Agregar Item
                                    </button>
                                  </div>
                                  <div class="col-lg-12 col-md-12 col-sm-12">
                                    <br />
                                    <table class="table table-sm table-hover">
                                      <thead class="thead-dark">
                                        <tr>
                                          <th scope="col"></th>
                                          <th scope="col">Nombre</th>
                                          <th scope="col">Tipo de control</th>
                                          <th scope="col">
                                            Tamaño del control
                                          </th>
                                          <th scope="col">Relevante</th>
                                          <th scope="col">
                                            Calif. Mantenimiento
                                          </th>
                                          <th scope="col">Calif. HSE</th>
                                          <th scope="col">Acciones</th>
                                          <th scope="col">Orden</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr
                                          v-for="(itemInspeccion,
                                          index) in itemsSeccion"
                                          :key="itemInspeccion.id"
                                        >
                                          <th>
                                            {{ index + 1 }}
                                          </th>
                                          <td>{{ itemInspeccion.nombre }}</td>
                                          <td>
                                            {{ itemInspeccion.nTipoControl }}
                                          </td>
                                          <td>
                                            {{ itemInspeccion.tamano_control }}
                                          </td>
                                          <td class="text-center">
                                            <i
                                              :class="
                                                itemInspeccion.pivot
                                                  .relevante == 1
                                                  ? 'fa fa-check text-success'
                                                  : 'fa fa-times-circle text-danger'
                                              "
                                            ></i>
                                          </td>
                                          <td class="text-center">
                                            <i
                                              :class="
                                                itemInspeccion.calificacion_novedades_mantenimiento ==
                                                1
                                                  ? 'fa fa-check text-success'
                                                  : 'fa fa-times-circle text-danger'
                                              "
                                            ></i>
                                          </td>
                                          <td class="text-center">
                                            <i
                                              :class="
                                                itemInspeccion.calificacion_novedades_hse ==
                                                1
                                                  ? 'fa fa-check text-success'
                                                  : 'fa fa-times-circle text-danger'
                                              "
                                            ></i>
                                          </td>
                                          <td>
                                            <div class="btn-group float-right">
                                              <button
                                                data-toggle="modal"
                                                data-target="#Modal_LineasNegocio"
                                                class="btn btn-sm btn-secondary"
                                                style="float: right"
                                                @click="
                                                  getItemLineasNegocio(
                                                    itemInspeccion.nombre,
                                                    itemInspeccion.pivot.id
                                                  )
                                                "
                                              >
                                                <i class="fas fa-eye"></i>
                                              </button>
                                              <button
                                                type="button"
                                                class="btn btn-sm bg-navy"
                                                data-toggle="modal"
                                                data-target="#modal-form-edit"
                                                @click="
                                                  editItemsSeccion(
                                                    1,
                                                    itemInspeccion.id,
                                                    itemInspeccion.pivot.id
                                                  )
                                                "
                                              >
                                                <i class="fas fa-edit"></i>
                                              </button>
                                              <button
                                                class="btn btn-sm btn-danger"
                                                style="float: right"
                                                @click="
                                                  saveDestroyItems(
                                                    2,
                                                    itemInspeccion.id,
                                                    itemInspeccion.pivot.id
                                                  )
                                                "
                                              >
                                                <i class="fas fa-trash"></i>
                                              </button>
                                            </div>
                                          </td>
                                          <td>
                                            <div class="btn-group float-right">
                                              <button
                                                class="btn btn-sm btn-success"
                                                v-if="index + 1 != 1"
                                                @click="subirOrden(index)"
                                              >
                                                <i
                                                  class="fas fa-arrow-up"
                                                ></i></button
                                              ><button
                                                class="btn btn-sm btn-danger"
                                                v-if="
                                                  index + 1 !=
                                                    itemsSeccion.length
                                                "
                                                @click="bajarOrden(index)"
                                              >
                                                <i
                                                  class="fas fa-arrow-down"
                                                ></i>
                                              </button>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
import moment from "moment";
import "moment/locale/es";

export default {
  name: "EvaluacionForm",
  components: {
    Loading,
    vSelect,
  },
  data() {
    return {
      fechaAct: moment().format("YYYY-MM-DD"),
      secciones: {},
      cargando: true,
      lineas_negocios: [],
      form: {
        nombre: null,
        linea_negocio_id: null,
        estado: null,
      },
      accion: "",
      metodo: "",
      listasForms: {
        lineas_negocios: [],
        estados: [],
      },
    };
  },
  validations() {
    return {
      form: {
        nombre: {
          required,
        },
        linea_negocio_id: {
          required,
        },
        estado: {
          required,
        },
      },
    };
  },

  methods: {
    async getIndex() {
      this.cargando = true;
      this.accion = this.$route.params.accion;
      if (this.$route.params.accion == "Editar") {
        this.metodo = "PUT";
      } else {
        this.metodo = "POST";
      }
      this.cargando = false;
    },

    async getEstados() {
      axios.get("/api/lista/28").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    getLineasN() {
      axios.get("/api/admin/lineasNegocios/lista").then((response) => {
        this.listasForms.lineas_negocios = response.data;
      });
    },

    getSelectLineas() {
      this.form.linea_negocio_id = null;
      if (this.lineas_negocios) {
        this.form.linea_negocio_id = this.lineas_negocios.id;
      }
    },

    back() {
      return this.$router.replace("/admin/Evaluaciones");
    },
  },

  mounted() {
    this.getIndex();
    this.getEstados();
    this.getLineasN();
  },
};
</script>
